import React from "react";
import RestaurantCategorySliderItem from "./RestaurantCategorySliderItem";
import Button from "@mui/material/Button";


export default function RestaurantCategorySlider({
                                                     category_data, selectedCategories, setSelectedCategories
                                                 }) {
    return (<div style={{paddingBottom: "31px"}}>
        {category_data?.length > 0 && (<div
            className="slider-wrapper secondary-slider-wrapper">

            {category_data.map((category) => (<RestaurantCategorySliderItem
                key={category.id}
                category={category}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}/>))}
        </div>)}
        {selectedCategories?.length > 0 && (
            <div style={{
                marginLeft: '1rem',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
            }}>
                {selectedCategories.length} selected <Button
                sx={{borderColor: 'black'}}
                size={'small'}
                onClick={() => setSelectedCategories([])}
                variant={'outlined'}>
                Clear Filters
            </Button>
            </div>)}
    </div>)
}