export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const SEND_OTP = "SEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const RUNNING_ORDER = "RUNNING_ORDER";
export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL";
export const VERIFY_PASSWORD_RESET_OTP = "VERIFY_PASSWORD_RESET_OTP";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const SAVE_VAT_NUMBER = "SAVE_VAT_NUMBER";
export const GENERATE_OTP_FOR_LOGIN = "GENERATE_OTP_FOR_LOGIN";