import Button from "@mui/material/Button";
import {APP_URL} from "../../../configs/consts";
import {isDeviceAndroid} from "./utilities";


export default function InstallAppHeaderButton() {

    const isAndroid = isDeviceAndroid();
    if (!isAndroid) {
        return null;
    }
    //"intent://#Intent;scheme=foodrunners;package=nz.co.foodrunners.app;browser_fallback_url="+encodeURIComponent(APP_URL)+";end;"
    return (
        <div style={{
            padding: "10px 0",
            minWidth: "100px",
            display: "flex",
            justifyContent: "start",

        }}>
            <Button sx={{textTransform: "none",}} variant="contained" target={"_blank"} color="primary" href={APP_URL}>
                Use App
            </Button>
        </div>
    )
}