import React from "react";

import Ink from "react-ink";
import {createSearchParams, Link, useNavigate} from "react-router-dom-v5-compat";
import InstallAppHeaderButton from "../Home/InstallAppHeaderButton";
import {useGetCartQuery} from "../../../services/cart/queries";


export default function Nav({disable_back_button, logo, logoLink}) {
    const navigate = useNavigate();
    const {isLoading: loadingCart, data: cart} = useGetCartQuery()


    return (
        <React.Fragment>
            <div className="col-12 p-0 sticky-top">
                <div className="block m-0">
                    <div className="block-content p-0">
                        <div className="input-group flex-nowrap search-box">
                            {!disable_back_button && (
                                <div className="input-group-prepend">
                                    <button
                                        type="button"
                                        className="btn search-navs-btns"
                                        style={{position: "relative"}}
                                    >
                                        <i className="si si-arrow-left"/>
                                        <Ink duration="500"/>
                                    </button>
                                </div>
                            )}
                            <p className="form-control search-input"
                               style={{paddingLeft: 0, paddingRight: 0, minWidth: "130px", flexGrow: 0}}>
                                {logo &&
                                    (logoLink ? (
                                        <Link to="/">
                                            <img
                                                src={`/assets/img/logos/${localStorage.getItem("storeLogo")}`}
                                                alt={localStorage.getItem("storeName")}
                                                className="store-logo"
                                            />
                                        </Link>
                                    ) : (
                                        <img
                                            src={`/assets/img/logos/${localStorage.getItem("storeLogo")}`}
                                            alt={localStorage.getItem("storeName")}
                                            className="store-logo"
                                        />
                                    ))}
                            </p>
                            <InstallAppHeaderButton/>

                            <button
                                type="submit"
                                className="btn nav-location d-inline-flex align-items-center"
                                style={{position: "relative"}}
                                id={'addressButton'}
                                onClick={() => {
                                    navigate({
                                        pathname: "/search-location",
                                        search: createSearchParams({
                                            backUrl: window.location.pathname
                                        }).toString()
                                    });
                                }}
                            >
                                {!loadingCart && (
                                    <span className={"truncate-text"}>
                                            {cart?.address?.address || "Select Location"}
                                        </span>

                                )}
                                <i
                                    className="si si-arrow-right nav-location-icon ml-1"
                                    style={{color: localStorage.getItem("storeColor")}}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}