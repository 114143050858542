import {WEBSITE_URL} from "../../../../configs/website";
import {AccessTimeFilled} from "@mui/icons-material";
import {Chip} from "@mui/material";
import React from "react";
import Ink from "react-ink";
import PromoSlider from "../PromoSlider";
import moment from "moment";
import RestaurantCategorySlider from "./RestaurantCategorySlider";
import {Link} from "react-router-dom-v5-compat";
import {restaurantAcceptsScheduleOrders} from "../utilities";
import {Restaurant} from "../../../../configs/types";


export default function RestaurantListItem({
                                               restaurant,
                                               selfpickup,
                                               index,
                                               lastChild,
                                               slides,
                                               category_data,
                                               selectedCategories,
                                               setSelectedCategories
                                           }: {
    restaurant: Restaurant,
    selfpickup: ?boolean,
    index: Number,
    lastChild: boolean,
    slides: Array,
    category_data: ?Array,
    selectedCategories: Array,
    setSelectedCategories: Function
}) {

    const getCloseOpenString = (restaurant: ?Restaurant) => {
        if (!restaurant.is_active && restaurant.is_schedulable && restaurant.accept_scheduled_orders) {
            let closeOpenString = "";
            try {
                const now = moment();
                let schedule;
                try {
                    schedule = JSON.parse(restaurant.schedule_data);
                } catch (e) {
                    return "";
                }
                if (!schedule || Object.keys(schedule).length === 0) {
                    return "";
                }
                const today = Intl.DateTimeFormat('en-NZ', {weekday: 'long'}).format(new Date()).toLowerCase()
                const todaySched = schedule[today];
                let slot;
                if (todaySched) {
                    for (slot of todaySched) {
                        let startDate = moment(slot.open, "HH:mm")
                        let endDate = moment(slot.close, "HH:mm")
                        if (endDate <= now) {
                            //slot is in the past
                            continue
                        }
                        // console.log("opening " + startDate.toISOString() + " closing " + endDate.toISOString())
                        if (startDate > now) {
                            closeOpenString = "Opens " + moment(startDate).format('h:mm a')
                            break
                        }
                        if (now >= startDate && now < endDate) {
                            closeOpenString = "Closes " + moment(endDate).format('h:mm a')
                            break
                        }
                    }
                }
                if (!closeOpenString) {
                    let searchNum = 1
                    while (true) {
                        let day = moment().add(searchNum, 'days');
                        let schedForDay = schedule[day.format('dddd').toLowerCase()];
                        if (schedForDay && schedForDay.length > 0) {
                            let startDate = moment(schedForDay[0].open, "HH:mm")
                            startDate.set("days", day.get('days'))
                            closeOpenString = "Opens " + startDate.format("dddd") + " " + startDate.format(" h:mma")
                            break
                        } else {
                            searchNum += 1
                            if (searchNum > 7) {
                                return "";
                            }
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            return closeOpenString
        }

    }
    let extra_class = ""
    if (lastChild) {
        extra_class = "last-child"
    }


    return (
        <React.Fragment key={restaurant.id}>
            <div className={"col-xs-12 col-sm-12 restaurant-block " + extra_class}>
                <Link
                    to={"stores/" + restaurant.slug}
                    className="block text-center pt-0 mb-3 single-store-homepage"
                >
                    <div
                        className={`block-content block-content-full ${
                            restaurant.is_featured && restaurant.is_active
                                ? "ribbon ribbon-bookmark ribbon-warning pt-2"
                                : "pt-2"
                        } `}
                        style={{position: 'relative'}}
                    >
                        <img
                            loading={"lazy"}
                            src={WEBSITE_URL + restaurant.image}
                            alt={restaurant.name}
                            className={`restaurant-image ${!restaurant.is_active &&
                            "restaurant-not-active"}`}
                        />
                        {(restaurantAcceptsScheduleOrders(restaurant) && restaurant?.is_active === 0) && (
                            <div className={'restaurant-closed-but-schedulable'}>
                                <div className={"d-flex flex-row align-items-center"}>
                                    <AccessTimeFilled sx={{marginRight: "3px"}}/>
                                    Schedule Order
                                </div>
                                <span
                                    style={{zIndex: 9}}>{getCloseOpenString(restaurant)}</span>
                            </div>

                        )}

                    </div>
                    <div className="block-content block-content-full restaurant-info">
                        <div className={'ribbon ribbon-warning ribbon-bookmark mb-5 mt-5'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                             }}>
                            <div className="restaurant-list-name text-dark" style={{
                                flexGrow: 1,
                                gap: '5px',
                                alignItems: 'center',
                                display: 'flex',
                            }}>
                                {restaurant.name}
                                {(restaurant.reward_earning_blurb && restaurant.reward_earning_blurb !== "") && (
                                    <Chip label={restaurant.reward_earning_blurb}
                                          avatar={<img style={{backgroundColor: 'transparent'}}
                                                       src={WEBSITE_URL + '/assets/img/food-runners-coin.png'}
                                                       alt=""/>}
                                          size={"medium"}
                                          color={"primary"}
                                          className={'runner-reward-background-color badges item-category-chip'}
                                    />
                                )}
                            </div>
                            {restaurant.is_featured ? (
                                <React.Fragment>
                                    {restaurant.custom_featured_name == null ? (
                                        <div className="ribbon-box">
                                            {localStorage.getItem("restaurantFeaturedText")}
                                        </div>
                                    ) : (
                                        <div className="ribbon-box">
                                            {restaurant.custom_featured_name}
                                        </div>
                                    )}
                                </React.Fragment>
                            ) : null}
                        </div>

                        <div className="font-size-sm text-muted text-muted" style={{
                            fontSize: '0.99rem'
                        }}>
                            {restaurant.description}
                        </div>
                        {restaurant.custom_message_on_list !== null &&
                            restaurant.custom_message_on_list !== "<p><br></p>" && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: restaurant.custom_message_on_list,
                                    }}
                                />
                            )}

                        {(!restaurant.is_active &&
                            !restaurantAcceptsScheduleOrders(restaurant)) && (
                            <span className="restaurant-not-active-msg">
															{localStorage.getItem("restaurantNotActiveMsg")}
							</span>
                        )}
                        {(!restaurant.is_active &&
                            restaurantAcceptsScheduleOrders(restaurant)) && (
                            <span className="restaurant-not-active-msg">
															{localStorage.getItem("restaurantNotActiveMsg")}, order for later
							</span>
                        )}
                        <hr className="my-10"/>
                        <div className="text-center restaurant-meta mt-5 d-flex
													align-items-center justify-content-between text-muted" style={{
                            fontSize: '0.9rem',
                            fontWeight: 500,
                        }}>
                            <div className="col-6 p-0 text-left store-distance-block">
                                {selfpickup ? (
                                    <span>
                                            <i className="si si-pointer pr-1"/>
                                        {restaurant.distance &&
                                            restaurant.distance.toFixed(1)}{" "}
                                        Km
										</span>
                                ) : (
                                    <span>
										<i className="si si-clock pr-1"/>{" "}
                                        {restaurant.delivery_time}{" "}
                                        {localStorage.getItem("homePageMinsText")}
										</span>
                                )}
                            </div>
                            <div className="col-6 p-0 text-right store-rating-block">
                                <i
                                    className={`fa fa-star pr-1 ${!restaurant.is_active &&
                                    "restaurant-not-active"}`}
                                    style={{
                                        color: localStorage.getItem("storeColor"),
                                    }}
                                />{" "}
                                {restaurant.avgRating === "0"
                                    ? restaurant.rating
                                    : restaurant.avgRating}
                                {restaurant.review_count > 0 ? " (" + restaurant.review_count + ")" : " (0)"}
                            </div>
                        </div>
                    </div>
                    <Ink duration="500" hasTouch={false}/>
                </Link>
            </div>
            {localStorage.getItem("showPromoSlider") === "true" && (
                <React.Fragment>
                    {slides && slides.length > 0 && (
                        <React.Fragment>
                            {index ===
                                slides[0]["promo_slider"]["position_id"] - 1 && (
                                    <PromoSlider
                                        slides={slides}
                                        size={slides[0]["promo_slider"]["size"]}
                                        secondarySlider={true}
                                    />
                                )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            {(localStorage.getItem("enRestaurantCategorySlider") === "true" && category_data) && (
                <React.Fragment>
                    {(index ===
                        parseInt(localStorage.getItem("restaurantCategorySliderPosition")) -
                        1 && selectedCategories.length < 1) && (
                        <RestaurantCategorySlider category_data={category_data} selectedCategories={selectedCategories}
                                                  setSelectedCategories={setSelectedCategories}/>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}