import React, {useEffect} from "react";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav/Nav";
import PromoSlider from "./PromoSlider";
import RestaurantList from "./RestaurantList/RestaurantList";
import {useSelector} from "react-redux";

import {Link} from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import GpsSelector from "../Location/PopularPlaces/GpsSelector";
import Ink from "react-ink";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Axios from "axios";
import {GET_POPULAR_LOCATIONS_URL, SET_ADDRESS_FROM_POPULAR_GEO_LOCATION_URL} from "../../../configs";
import {useGetCartQuery, useGetPromoSlidesQuery} from "../../../services/cart/queries";
import Loading from "../../helpers/loading";

export default function Home() {
    const [open, setOpen] = React.useState(false);
    const {user} = useSelector((state) => ({
        user: state.user.user
    }));
    const {isLoading: loadingCart, isError, data: cart, error: errorCart} = useGetCartQuery()
    const {data: popularGeoLocations, isLoading: geoLocationLoading} = useQuery(
        {
            queryKey: ["popularGeoLocations"],
            queryFn: () => Axios.get(GET_POPULAR_LOCATIONS_URL),
            staleTime: Infinity,
            refetchOnMount: false,
        }
    )
    const {
        data: promo_slides_data,
        isLoading: promoSlidesLoading
    } = useGetPromoSlidesQuery(cart?.address, loadingCart, geoLocationLoading)
    const promo_slides = promo_slides_data?.data
    const queryClient = useQueryClient()
    const setPopularLocationMutation = useMutation((location) => {
        return Axios.post(SET_ADDRESS_FROM_POPULAR_GEO_LOCATION_URL, {
            id: location.id,
        });
    }, {
        onSuccess: (data) => {
            queryClient.setQueryData(["cart"], data.data);
            queryClient.invalidateQueries(['cart_totals'])
        },
    });

    let popular_locations = [];
    // console.log(popularGeoLocations)
    if (popularGeoLocations && popularGeoLocations.data) {
        popular_locations = popularGeoLocations.data;
    }
    useEffect(() => {
        if (cart && !cart?.address) {
            if (popular_locations.length > 0) {
                setOpen(true);
            }
        }
    }, [cart, popular_locations]);
    const handlePopularLocationClick = (location) => {
        setPopularLocationMutation.mutate(location);
        setOpen(false);
    };

    if (promoSlidesLoading)
        return <Loading/>


    return (
        <React.Fragment>
            <Meta
                seotitle={localStorage.getItem("seoMetaTitle")}
                seodescription={localStorage.getItem("seoMetaDescription")}
                ogtype="website"
                ogtitle={localStorage.getItem("seoOgTitle")}
                ogdescription={localStorage.getItem("seoOgDescription")}
                ogurl={window.location.href}
                twittertitle={localStorage.getItem("seoTwitterTitle")}
                twitterdescription={localStorage.getItem("seoTwitterDescription")}
            />
            <div className="bg-white">
                <Nav
                    logo={true}
                    active_nearme={true}
                    disable_back_button={true}
                    loggedin={user.success}
                />

                {/* Passing slides as props to PromoSlider */}
                {localStorage.getItem("showPromoSlider") === "true" && (
                    <React.Fragment>
                        {promo_slides && promo_slides.mainSlides && promo_slides.mainSlides.length > 0 && (
                            <PromoSlider
                                slides={promo_slides.mainSlides}
                                size={promo_slides.mainSlides[0]["promo_slider"]["size"]}
                            />
                        )}
                    </React.Fragment>
                )}

                {localStorage.getItem("mockSearchOnHomepage") === "true" && (
                    <Link to="explore">
                        <div
                            className={`mock-search-block px-15 pb-10 ${
                                localStorage.getItem("showPromoSlider") === "false"
                                    ? "pt-15"
                                    : "" + typeof promo_slides?.mainSlides === "null"
                                        ? "pt-15"
                                        : ""
                            }`}
                        >
                            <div className="px-15 d-flex justify-content-between">
                                <div>
                                    <span>{localStorage.getItem("mockSearchPlaceholder")}</span>
                                </div>
                                <div>
                                    <i className="si si-magnifier"/>
                                </div>
                            </div>
                        </div>
                    </Link>
                )}

                {localStorage.getItem("customHomeMessage") !== "<p></p>" &&
                    localStorage.getItem("customHomeMessage") !== "<p><br></p>" &&
                    localStorage.getItem("customHomeMessage") !== "null" &&
                    (localStorage.getItem("customHomeMessage") !== "" && (
                        <div
                            style={{
                                position: "relative",
                                background: "#f8f9fa",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: localStorage.getItem("customHomeMessage"),
                            }}
                        />
                    ))}
                <RestaurantList user={user} slides={promo_slides?.otherSlides}/>
                <Footer active_nearme={true}/>
            </div>

            <Dialog
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
                open={open}
                style={{margin: "auto", position: "absolute", bottom: "0", top: "60%"}}
                PaperProps={{
                    style: {
                        backgroundColor: "#fff",
                        overflowY: "hidden",
                    },
                }}
            >
                <div>
                    <GpsSelector fetchGpsAutomaticallyAndroid={true}/>
                    <div className="p-15 popularLocationPopup">
                        {popular_locations && popular_locations.length > 0 && (
                            <React.Fragment>
                                <h4 className="text-muted h4">{localStorage.getItem("searchPopularPlaces")}</h4>

                                <div style={{overflowY: "scroll", height: "11rem"}}>
                                    {popular_locations.map((location) => (
                                        <button
                                            key={location.id}
                                            type="button"
                                            className="btn btn-rounded btn-alt-secondary btn-md mb-15 mr-15"
                                            style={{
                                                position: "relative",
                                                backgroundColor:
                                                    location.is_default && localStorage.getItem("storeColor"),
                                                color: location.is_default && "#fff",
                                            }}
                                            onClick={() => {
                                                handlePopularLocationClick(location);
                                            }}
                                        >
                                            <Ink duration="500"/>
                                            {location.name}
                                        </button>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
}