import {BrowserRouter, Route, Switch} from "react-router-dom";

import App from "./components/App";
import loadable from '@loadable/component';
import Loading from "./components/helpers/loading";
import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./Root";
import withTracker from "./withTracker";
import {CompatRoute, CompatRouter} from "react-router-dom-v5-compat";
import Loader from "./components/StoreOwner/Loader";
import './foodrunners.css'
import {createTheme, ThemeProvider} from "@mui/material";
import {green} from "@mui/material/colors";
import Axios from "axios";
import Mobile from "./components/Mobile/Mobile";
import "inter-ui/inter.css";
import * as Sentry from "@sentry/react";
import {HttpClient} from "@sentry/integrations";

// import NotFound from "./components/NotFound";
const NotFound = loadable(() => import("./components/NotFound"), {
    fallback: (() => <Loading/>)(),
});

// import Location from "./components/Mobile/Location";
const Location = loadable(() => import("./components/Mobile/Location"), {
    fallback: (() => <Loading/>)(),
});

// import Items from "./components/Mobile/Items";
// const Items = Loadable({
// 	loader: () => import("./components/Mobile/Items"),
// 	loading: () => <Loading />,
// });

// import Login from "./components/Mobile/Auth/Login";
const Login = loadable(() => import("./components/Mobile/Auth"), {
    fallback: (() => <Loading/>)(),
});

// import Register from "./components/Mobile/Auth/Register";
const Register = loadable(() => import("./components/Mobile/Auth/Register"), {
    fallback: (() => <Loading/>)(),
});

// import CartPage from "./components/Mobile/Cart";
const CartPage = loadable(() => import("./components/Mobile/Cart/Cart"), {
    fallback: (() => <Loading/>)(),
});

const CartDetailEditPage = loadable(() => import("./components/Mobile/Cart/Address/AddressDetailEditPage"), {
    fallback: (() => <Loading/>)(),
});

const RewardsRedeemList = loadable(() => import("./components/Mobile/Cart/RewardsRedeem/RewardsRedeemList"), {
    fallback: (() => <Loading/>)(),
});

const SavedPaymentMethodPage = loadable(() => import("./components/Mobile/Cart/Payment/SavedPaymentMethodPage"), {
    fallback: (() => <Loading/>)(),
});

const EditCartItem = loadable(() => import("./components/Mobile/Customization/EditCartItem"), {
    fallback: (() => <Loading/>)(),
});
// import Account from "./components/Mobile/Account";
const Account = loadable(() => import("./components/Mobile/Account"), {
    fallback: (() => <Loading/>)(),
});

// import Explore from "./components/Mobile/Explore";
const Explore = loadable(() => import("./components/Mobile/Explore"), {
    fallback: (() => <Loading/>)(),
});

// import RunningOrder from "./components/Mobile/RunningOrder";
// const RunningOrder = loadable(() => import("./components/Mobile/RunningOrder/RunningOrder"), {
//     fallback: (() => <Loading/>)(),
// });

const RunningOrder = loadable(() => import("./components/Mobile/RunningOrder/RunningOrder"), {
    fallback: (() => <Loading/>)(),
});

// import Orders from "./components/Mobile/Account/Orders";
const Orders = loadable(() => import("./components/Mobile/Account/Orders"), {
    fallback: (() => <Loading/>)(),
});

// import WalletPage from "./components/Mobile/Account/Wallet";
const WalletPage = loadable(() => import("./components/Mobile/Account/Wallet"), {
    fallback: (() => <Loading/>)(),
});
/* Delivery */
// import Delivery from "./components/Delivery";
const Delivery = loadable(() => import("./components/Delivery"), {
    fallback: (() => <Loading/>)(),
});

// import DeliveryLogin from "./components/Delivery/Login";
const DeliveryLogin = loadable(() => import("./components/Delivery/Login"), {
    fallback: (() => <Loading/>)(),
});

// import DeliveryOrders from "./components/Delivery/Orders";
const DeliveryOrders = loadable(() => import("./components/Delivery/Orders"), {
    fallback: (() => <Loading/>)(),
});

// import ViewOrder from "./components/Delivery/ViewOrder";
const ViewOrder = loadable(() => import("./components/Delivery/ViewOrder"), {
    fallback: (() => <Loading/>)(),
});

// import GeoLocationPage from "./components/Mobile/GeoLocationPage";
const GeoLocationPage = loadable(() => import("./components/Mobile/GeoLocationPage"), {
    fallback: (() => <Loading/>)(),
});

// import SingleItem from "./components/Mobile/Items/SingleItem";
// const SingleItem = Loadable({
// 	loader: () => import("./components/Mobile/Items/SingleItem"),
// 	loading: () => <Loading />,
// });

const Items = loadable(() => import("./components/Mobile/Items/ItemsComponent"), {
    fallback: (() => <Loading/>)(),
});

const CustomizeNewItem = loadable(() => import("./components/Mobile/Items/ItemList/CustomizeNewItem"), {
    fallback: (() => <Loading/>)(),
});

const SinglePage = loadable(() => import("./components/SinglePage"), {
    fallback: (() => <Loading/>)(),
});

const ForgotPassword = loadable(() => import("./components/Mobile/Auth/ForgotPassword"), {
    fallback: (() => <Loading/>)(),
});

const RatingAndReview = loadable(() => import("./components/Mobile/Account/Orders/RatingAndReview"), {
    fallback: (() => <Loading/>)(),
});
const ViewStoreReviews = loadable(() => import("./components/Mobile/StoreReviews"), {
    fallback: (() => <Loading/>)(),
});

const Alerts = loadable(() => import("./components/Mobile/Alerts"), {
    fallback: (() => <Loading/>)(),
});

const FavoriteRestaurantList = loadable(() => import("./components/Mobile/Home/FavoriteRestaurants"), {
    fallback: (() => <Loading/>)(),
});

const LoginAsCustomer = loadable(() => import("./components/Mobile/Modules/LoginAsCustomer"), {
    fallback: (() => <Loading/>)(),
});

const StoreOwnerRoot = loadable(() => import("./components/StoreOwner/Root"), {
    fallback: (() => <Loader/>)(),
})

const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};
const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor) => augmentColor({color: {main: mainColor}});
const theme = createTheme({
    palette: {
        deliveryPurple: createColor("#8360c3"),
        deliveryOnlineGreen: createColor("#18c775"),
        deliveryOfflineRed: createColor("rgb(255, 0, 0)"),
        liveOrderGreen: createColor(green[500]),
        primary: createColor('rgb(25, 118, 210)'),
        remove: createColor("#d62929"),
        darkPurple: createColor('rgb(55, 0, 179)'),
        differentRed: createColor('#F64C72'),
        cartIconBrown: createColor('#d24d00'),
        runningOrderChip: createColor('rgb(43,128,28)'),
        runningOrderDeliveryRating: createColor('#f9d200'),
        runningOrderDeliveryRatingBackground: createColor('#575757'),
    },
    typography: {
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});
Axios.defaults.withCredentials = true;
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://b0b00ad89e35f0cb3be9d96e5fc37615@o4506036353564672.ingest.sentry.io/4506036355465216",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/dev\.foodrunners\.co\.nz\/api/],
            }),
            new Sentry.Replay({
                networkDetailAllowUrls: ['foodrunners.co.nz', window.location.origin],
            }),
            new HttpClient(),
        ],
        ignoreErrors: [
            /apstagLOADED is not defined/i,
            /__cmp is not defined/i,
            /Cannot read properties of undefined (reading 'cmp')/i,
            /Cannot read properties of undefined (reading 'outputCurrentConfiguration')/i,
            /IMUID is not defined/i,
            /jwDefaults is not defined/i,
            /YT is not defined/i,
            /dailymotion is not defined/i,
            /moat_px is not defined/i,
            /Cannot read properties of undefined (reading 'variables')/i,
            /Cannot read properties of undefined (reading 'ns')/i,
            /Cannot read properties of undefined (reading 'mvpConfig')/i,
            /ezDenty is not defined/i,
            /Can't find variable: setIOSParameters/i
        ],
        // Performance Monitoring
        tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}



// class DebugRouter extends BrowserRouter {
//     constructor(props) {
//         super(props);
//         console.log('initial history is: ', JSON.stringify(this.history, null, 2))
//         this.history.listen((location, action) => {
//             console.log(
//                 `The current URL is ${location.pathname}${location.search}${location.hash}`
//             )
//             console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2));
//         });
//     }
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Root>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CompatRouter>
                    <React.Fragment>
                        <Route component={ScrollToTop}/>
                        <App>
                            <Switch>
                                {/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}

                                <CompatRoute path={"/"} exact component={Mobile}/>
                                <Route path={"/search-location"} exact component={withTracker(Location)}/>
                                <Route path={"/my-location"} exact component={withTracker(GeoLocationPage)}/>

                                <CompatRoute path={"/stores/:restaurant"} exact component={withTracker(Items)}/>
                                <CompatRoute path={"/stores/:restaurant/add/:item_id"} exact component={withTracker(CustomizeNewItem)}/>
                                <CompatRoute path={"/stores/:restaurant_slug/redeems"} exact
                                             component={withTracker(RewardsRedeemList)}/>

                                <Route path={"/explore"} exact component={withTracker(Explore)}/>

                                <Route path={"/login"} exact component={withTracker(Login)}/>
                                <Route path={"/login/forgot-password"} exact component={withTracker(ForgotPassword)}/>
                                <Route path={"/register"} exact component={withTracker(Register)}/>

                                <Route path={"/my-account"} exact component={withTracker(Account)}/>
                                <Route path={"/alerts"} exact component={withTracker(Alerts)}/>
                                <Route path={"/my-wallet"} exact component={withTracker(WalletPage)}/>
                                <Route path={"/my-orders"} exact component={withTracker(Orders)}/>
                                <CompatRoute path={"/rate-order/:id"} exact component={withTracker(RatingAndReview)}/>
                                <Route path={"/reviews/:slug"} exact component={withTracker(ViewStoreReviews)}/>

                                <CompatRoute path={"/running-order/:unique_order_id"} exact
                                       component={withTracker(RunningOrder)}/>

                                <CompatRoute path={"/cart"} exact component={withTracker(CartPage)}/>
                                <CompatRoute path={'/cart/edit/:cart_index'} exact component={withTracker(EditCartItem)}/>
                                <CompatRoute path={'/cart/address_details'} exact component={withTracker(CartDetailEditPage)}/>
                                <CompatRoute path={'/cart/payment_method'} exact component={withTracker(SavedPaymentMethodPage)}/>
                                <CompatRoute path={"/cart/redeem/:restaurant_slug"} exact
                                             component={withTracker(RewardsRedeemList)}/>

                                <Route path={"/pages/:slug"} exact component={withTracker(SinglePage)}/>
                                <Route path={"/my-favorite-stores"} exact
                                       component={withTracker(FavoriteRestaurantList)}/>
                                {/* Delivery Routes */}
                                <Route path={"/delivery"} exact component={Delivery}/>
                                <Route path={"/delivery/login"} exact component={DeliveryLogin}/>
                                <Route path={"/delivery/orders"} exact component={DeliveryOrders}/>
                                <Route path={"/delivery/orders/:unique_order_id"} exact component={ViewOrder}/>
                                <Route path={"/delivery/completed-orders"} exact component={Delivery}/>
                                {/* Common Routes */}
                                {/* Admin Login as Customer Module Routes */}
                                <Route path={"/auth/login-as-customer/:id?"} exact component={LoginAsCustomer}/>
                                {/* END Admin Login as Customer Module Routes */}
                                <CompatRoute path={"/store-owner/"} component={StoreOwnerRoot}/>
                                <Route component={NotFound}/>

                            </Switch>
                        </App>
                    </React.Fragment>
                </CompatRouter>
            </BrowserRouter>
        </ThemeProvider>
    </Root>
);
