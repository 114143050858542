import {combineReducers} from "redux";

import settingsReducer from "./settings/reducer";
import locationsReducer from "./locations/reducer";
import promoSliderReducer from "./promoSlider/reducer";
import itemsReducer from "./items/reducer";
import totalReducer from "./total/reducer";
import couponReducer from "./coupon/reducer";
import userReducer from "./user/reducer";
import pagesReducer from "./pages/reducer";
import searchReducer from "./searchRestaurants/reducer";
import addressesReducer from "./addresses/reducer";
import checkoutReducer from "./checkout/reducer";
import ordersReducer from "./orders/reducer";
import paymentGatewayReducer from "./paymentgateways/reducer";
import notificationReducer from "./notification/reducer";
import restaurantReducer from "./restaurant/reducer";
import ratingReducer from "./rating/reducer";
import alertReducer from "./alert/reducer";

import deliveryUserReducer from "./Delivery/user/reducer";
import deliveryOrdersReducer from "./Delivery/orders/reducer";
import deliverySingleOrderReducer from "./Delivery/singleorder/reducer";
import gpsLocationReducer from "./Delivery/gpslocation/reducer";
import acceptToDeliver from "./Delivery/deliveryprogress/reducer";

import storeOwnerUserReducer from "./StoreOwner/user/reducer"

import helperReducer from "./helper/reducer";

export default combineReducers({
	settings: settingsReducer,
	locations: locationsReducer,
	promo_slides: promoSliderReducer,
	items: itemsReducer,
	total: totalReducer,
	coupon: couponReducer,
	user: userReducer,
	pages: pagesReducer,
	searches: searchReducer,
	addresses: addressesReducer,
	checkout: checkoutReducer,
	orders: ordersReducer,
	paymentgateways: paymentGatewayReducer,
	delivery_user: deliveryUserReducer,
	delivery_orders: deliveryOrdersReducer,
	single_delivery_order: deliverySingleOrderReducer,
	gps_location: gpsLocationReducer,
	accepted_order: acceptToDeliver,
	notification_token: notificationReducer,
	restaurant: restaurantReducer,
	rating: ratingReducer,
	alert: alertReducer,
	helper: helperReducer,
	store_owner_user: storeOwnerUserReducer,
});
