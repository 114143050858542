import {WEBSITE_URL} from "../../../../configs/website";
import Ink from "react-ink";
import React from "react";

function intersect(a, b) {
    const setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
}

export default function RestaurantCategorySliderItem({category, selectedCategories, setSelectedCategories}) {
    const category_ids:Array = category.categories_ids.map(
        (item) => item.value
    );
    return (
        <div
            className="slider-wrapper__img-wrapper"
            key={category.id}
            style={{
                // this checks if any of the selected categories is in the current category
                background: intersect(selectedCategories, category_ids).length > 0
                    ? "linear-gradient(137deg, rgb(120 196 255) 0%, rgb(100 15 218) 100%"
                    : "transparent",
            }}
        >
            <div
                style={{position: "relative"}}
                onClick={() => {
                    setSelectedCategories((prev) => {
                        if (intersect(prev, category_ids).length > 0) {
                            return prev.filter(
                                (item) => category_ids.indexOf(item) === -1
                            );
                        } else {
                            return [...prev, ...category_ids];
                        }
                    });
                }}
            >
                <img
                    src={WEBSITE_URL + category.image}
                    alt={category.name}
                    loading={"lazy"}
                    className="slider-wrapper__img slider-cust-img"
                    style={{
                        height:
                            (12 / 5) *
                            parseInt(
                                localStorage.getItem(
                                    "restaurantCategorySliderSize"
                                )
                            ) +
                            "rem",
                        width:
                            (12 / 5) *
                            parseInt(
                                localStorage.getItem(
                                    "restaurantCategorySliderSize"
                                )
                            ) +
                            "rem",
                        borderRadius:
                            parseFloat(
                                localStorage.getItem(
                                    "restaurantCategorySliderStyle"
                                )
                            ) + "rem",

                    }}
                />
                {localStorage.getItem(
                    "showRestaurantCategorySliderLabel"
                ) === "true" && (
                    <span
                        className="category-slider-name">
																					{category.name}
																				</span>
                )}
                <Ink duration="500" hasTouch={true}/>
            </div>
        </div>
    )
}