import axios from "axios";
import Axios from "axios";
import {
    ADD_TO_CART_URL,
    GET_CART_TOTALS_AUTHENTICATED_URL,
    GET_CART_TOTALS_URL,
    GET_CART_URL,
    GET_FAVORITE_RESTAURANT_FOR_LOGGED_IN_URL,
    GET_PROMO_SLIDER_URL,
    GET_RESTAURANT_INFO_URL,
    GET_RESTAURANT_ITEMS_URL,
    GET_VALID_REDEEMS_URL,
    PERFORM_REORDER_URL,
    REMOVE_FROM_CART_URL,
    SET_ADDRESS_DETAILS_LOGGED_IN_URL,
    SET_ADDRESS_DETAILS_URL,
    SET_CART_IS_DELIVERY,
    SET_CART_SCHEDULE_URL,
    SET_CART_STRIPE_PAYMENT_METHOD_ID_URL,
    SET_CART_TIP_URL,
    SET_REWARDS_REDEEMING_URL,
    SET_USE_WALLET_URL,
    SET_VOUCHER_URL,
    STRIPE_DELETE_PAYMENT_METHOD,
    STRIPE_SAVED_PAYMENT_METHODS,
    UPDATE_CART_ITEM_URL
} from "../../configs";
import {useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";

export async function getCart() {
    // if (token)
    //     return (await axios.get(GET_CART_URL, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })).data
    // else
    return (await axios.get(GET_CART_URL)).data
}

export const useGetCartQuery = (): UseQueryResult => {
    return useQuery(
        {
            queryKey: ['cart'],
            queryFn: () => getCart(),
            staleTime: 15000,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        });
}

const addItem = async (item) => {
    return (await axios.post(ADD_TO_CART_URL, item)).data
}

export const useAddItemMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(['addItem'], {
        mutationFn: addItem,

        onSuccess: (data, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
    })
}

const updateItem = async ({item, index}) => {
    return (await axios.post(UPDATE_CART_ITEM_URL, {item, index})).data
}

export const useUpdateItemMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(['updateItem'], {
        mutationFn: updateItem,

        onSuccess: (data, variables) => {
            console.log('update sees ', data)
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
    })
}


const removeItem = async ({index, full}:{index: number, full: boolean}) => {
    return (await axios.post(REMOVE_FROM_CART_URL, {index, full})).data
}

export const useRemoveItemMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(['removeItem'], {
        mutationFn: removeItem,

        onSuccess: (data, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
    })
}

export const getRestaurantInfo = async (slug, token) => {
    if (token)
        return (await axios.post(GET_FAVORITE_RESTAURANT_FOR_LOGGED_IN_URL + "/" + slug, {
            token: token,
        })).data
    else
        return (await axios.get(GET_RESTAURANT_INFO_URL + "/" + slug)).data
}
export const getRestaurantItems = async (slug) => {
    return (await axios.get(GET_RESTAURANT_ITEMS_URL + "/" + slug)).data
}

export const useSetDeliveryTypeMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(['changeDeliveryType'], {
        mutationFn: async (is_delivery: boolean) => {
            return (await Axios.post(SET_CART_IS_DELIVERY, {
                delivery_type: is_delivery
            })).data
        },

        onSuccess: (data, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
    });
}


export const useGetPromoSlidesQuery = (address) => {
    return useQuery(
        {
            queryKey: ["promo_slides", address?.latitude, address?.longitude],
            queryFn: async () => {
                if (address?.latitude && address?.longitude)
                    return await Axios.post(GET_PROMO_SLIDER_URL, {
                        latitude: address?.latitude,
                        longitude: address?.longitude,
                    })
                else return []
            },
            staleTime: Infinity,
        });
}

export const useGetCartTotalsQuery = (auth_token: ?string): UseQueryResult => {
    return useQuery(
        {
            queryKey: ["cart_totals", auth_token],
            queryFn: async () => {
                if (auth_token)
                    return await (await axios.get(GET_CART_TOTALS_AUTHENTICATED_URL, {
                        headers: {
                            Authorization: `Bearer ${auth_token}`,
                        },
                    })).data
                else
                    return await (await axios.get(GET_CART_TOTALS_URL)).data
            },
            staleTime: 15000,
        });
}

export const useSetScheduleMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(['setSchedule'], {
        mutationFn: async (schedule) => {
            return (await Axios.post(SET_CART_SCHEDULE_URL, {
                schedule_start: schedule
            })).data
        },

        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
    });
}

export const useGetSavedStripeCardsQuery = (auth_token) => useQuery({
    queryKey: ['stripe_saved_methods'],
    queryFn: () => {
        return Axios.post(STRIPE_SAVED_PAYMENT_METHODS, {
            token: auth_token
        })
    },
    enabled: !!auth_token,
});

export const useSavePaymentMethodMutation = (actions: function): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({id, saveCard, isSavedCard}) => {
            return await Axios.post(SET_CART_STRIPE_PAYMENT_METHOD_ID_URL, {
                id: id,
                save: saveCard,
                isSavedCard
            })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
            actions()
        },
    })
};

export const useDeleteSavedPaymentMethodMutation = (): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({auth_token, id}) => {
            return await Axios.post(STRIPE_DELETE_PAYMENT_METHOD, {
                paymentMethodId: id,
                token: auth_token
            })
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['stripe_saved_methods'])
            queryClient.invalidateQueries(['cart_totals'])
        },
    })
};

export const useSetAddressDetailsMutation = (): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({delivery_instructions, is_contactless, auth_token}) => {
            if (auth_token)
                return await Axios.post(SET_ADDRESS_DETAILS_LOGGED_IN_URL, {
                    delivery_instructions: delivery_instructions,
                    is_contactless: is_contactless || false,
                    token: auth_token
                })
            else
                return await Axios.post(SET_ADDRESS_DETAILS_URL, {
                    delivery_instructions: delivery_instructions,
                    is_contactless: is_contactless || false,
                    token: auth_token
                })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
    })
};

export const useGetValidRedeemsQuery = (enabled: boolean, token: string) => useQuery({
    queryKey: ['valid_redeems'],
    queryFn: () => {
        return Axios.get(GET_VALID_REDEEMS_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    },
    enabled,
});

export const useSetRewardsRedeemingMutation = (): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({redeems, auth_token}) => {
            return await Axios.post(SET_REWARDS_REDEEMING_URL, {
                rewardPointRedeems: redeems || [],
                token: auth_token
            })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
            queryClient.refetchQueries(['valid_redeems'])
        },
    })
};

export const useSetVoucherMutation = (onError: function): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({voucher, auth_token}) => {
            return await Axios.post(SET_VOUCHER_URL, {
                coupon: voucher,
                token: auth_token
            })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
        onError: (error, variables, context) => {
            queryClient.invalidateQueries(['cart_totals'])
            onError(error, variables, context);
        }
    })
};

export const useSetWalletUsageMutation = (): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({use_wallet, auth_token}) => {
            return await Axios.post(SET_USE_WALLET_URL, {
                useWallet: use_wallet,
                token: auth_token
            })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
        onError: (error) => {
            console.log('error', error)
            queryClient.invalidateQueries(['cart_totals'])
        }
    })
};

export const useSetTipMutation = (): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({tip}) => {
            return await Axios.post(SET_CART_TIP_URL, {
                tip: tip
            })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
        onError: (error) => {
            console.log('error', error)
            queryClient.invalidateQueries(['cart_totals'])
        }
    })
};

export const useReorderMutation = (): UseMutationResult => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({order_id, auth_token, force}) => {
            return await Axios.post(PERFORM_REORDER_URL, {
                token: auth_token,
                order_id: order_id,
                force
            })
        },
        onSuccess: ({data}, variables) => {
            queryClient.setQueryData(['cart'], data)
            queryClient.invalidateQueries(['cart_totals'])
        },
        onError: (error) => {
            console.log('error', error)
            queryClient.invalidateQueries(['cart_totals'])
        }
    })
};