import {
    GET_DELIVERY_RESTAURANTS_URL,
    GET_RESTAURANTS_SLIDES_URL,
    GET_SELFPICKUP_RESTAURANTS_URL
} from "../../../../configs/index";
import React, {useEffect} from "react";

import ContentLoader from "react-content-loader";
import Axios from "axios";
import RestaurantListItem from "./RestaurantListItem";
import {RestaurantListFooter} from "./RestaurantListFooter";
import RestaurantCategorySlider from "./RestaurantCategorySlider";
import {useQuery} from "@tanstack/react-query";
import {useGetCartQuery, useGetPromoSlidesQuery} from "../../../../services/cart/queries";
import DeliveryTypeSwitch from "./DeliveryTypeSwitch";

export const getRestaurantsQuery = async (isPickup, lat, lng, selectedCategories: Array) => {
    console.log("getRestaurantsQuery", isPickup, lat, lng, typeof selectedCategories)
    if (isPickup)
        return await Axios.post(GET_SELFPICKUP_RESTAURANTS_URL, {
            latitude: lat,
            longitude: lng,
            category_filter: selectedCategories
        })
    else
        return await Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
            latitude: lat,
            longitude: lng,
            category_filter: selectedCategories
        })
}

export default function RestaurantList() {
    const pickupEnabled = localStorage.getItem("enSPU") === "true";
    const [selectedCategories, setSelectedCategories] = React.useState([])
    const {isLoading: loadingCart,  data: cart, error: errorCart} = useGetCartQuery()
    const {
        isLoading: loadingRestaurants,
        data: restaurants
    } =
        useQuery({
            queryKey: ["restaurants", cart?.delivery, cart?.address, selectedCategories],
            queryFn: () => {
                return getRestaurantsQuery(!cart?.delivery, cart?.address?.latitude, cart?.address?.longitude, selectedCategories)
            },
            enabled: !loadingCart && !errorCart
        })
    const {
        isLoading: loadingCategorySlides,
        isError: errorSlides,
        data: category_slides
    } =
        useQuery({
            queryKey: ["slides"],
            staleTime: 1000 * 60 * 30, // 30 minutes
            queryFn: () => Axios.get(GET_RESTAURANTS_SLIDES_URL),
        })

    const {
        data: promo_slides,
        isLoading: promoSlidesLoading
    } = useGetPromoSlidesQuery(cart?.address, loadingCart, loadingCategorySlides)

    const anyLoading = loadingCart || loadingRestaurants || loadingCategorySlides || promoSlidesLoading

    useEffect(() => {
        if (selectedCategories?.length > 0)
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            })
    }, [selectedCategories]);
    localStorage.removeItem("dontShowBlurbPopup");
    return (
        <React.Fragment>
            <div className="bg-white mb-100">
                {(restaurants?.data?.length === 0 && !anyLoading) && (
                    <div
                        className={"bg-light " + (localStorage.getItem("enSPU") === "true" ? "sticky-top" : "pt-3")}
                    >
                        {pickupEnabled ? (
                            <React.Fragment>
                                <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                                    <h1 className="restaurant-count mb-0 mr-2">
                                        {localStorage.getItem("noRestaurantMessage")}
                                    </h1>

                                    <DeliveryTypeSwitch/>
                                </div>
                                <hr/>
                            </React.Fragment>
                        ) : (
                            <h1 className="restaurant-count mb-0 mr-2 px-15">
                                {localStorage.getItem("noRestaurantMessage")}
                            </h1>
                        )}
                        <hr/>
                    </div>
                )}
                {restaurants?.data?.length > 0 ? (
                    <React.Fragment>
                        <div
                            className={
                                "bg-light " + (pickupEnabled ? "sticky-top" : "pt-3")
                            }
                        >
                            {pickupEnabled ? (
                                <React.Fragment>
                                    <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                                        <h1 className="restaurant-count mb-0 mr-2">
                                            {!anyLoading && restaurants.data.length}{" "}
                                            {localStorage.getItem("restaurantCountText")}
                                        </h1>

                                        <DeliveryTypeSwitch/>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                                    <h1 className="restaurant-count mb-0 mr-2">
                                        {!anyLoading}{" "}
                                        {localStorage.getItem("restaurantCountText")}
                                    </h1>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ) : null}

                {(localStorage.getItem("restaurantCategorySliderPosition") === "0" || selectedCategories.length > 0) && (
                    <RestaurantCategorySlider category_data={category_slides?.data}
                                              selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}/>
                )}
                {anyLoading ? (
                    <ContentLoader
                        height={378}
                        width={400}
                        viewBox={`0 0 400 378`}
                        speed={1.2}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="20" y="20" rx="4" ry="4" width="80" height="78"/>
                        <rect x="144" y="30" rx="0" ry="0" width="115" height="18"/>
                        <rect x="144" y="60" rx="0" ry="0" width="165" height="16"/>

                        <rect x="20" y="145" rx="4" ry="4" width="80" height="78"/>
                        <rect x="144" y="155" rx="0" ry="0" width="115" height="18"/>
                        <rect x="144" y="185" rx="0" ry="0" width="165" height="16"/>

                        <rect x="20" y="270" rx="4" ry="4" width="80" height="78"/>
                        <rect x="144" y="280" rx="0" ry="0" width="115" height="18"/>
                        <rect x="144" y="310" rx="0" ry="0" width="165" height="16"/>
                    </ContentLoader>
                ) : (
                    <React.Fragment>
                        {restaurants?.data.length === 0 ? (
                            <ContentLoader
                                height={378}
                                width={400}
                                viewBox={`0 0 400 378`}
                                speed={1.2}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="20" y="20" rx="4" ry="4" width="80" height="78"/>
                                <rect x="144" y="30" rx="0" ry="0" width="115" height="18"/>
                                <rect x="144" y="60" rx="0" ry="0" width="165" height="16"/>

                                <rect x="20" y="145" rx="4" ry="4" width="80" height="78"/>
                                <rect x="144" y="155" rx="0" ry="0" width="115" height="18"/>
                                <rect x="144" y="185" rx="0" ry="0" width="165" height="16"/>

                                <rect x="20" y="270" rx="4" ry="4" width="80" height="78"/>
                                <rect x="144" y="280" rx="0" ry="0" width="115" height="18"/>
                                <rect x="144" y="310" rx="0" ry="0" width="165" height="16"/>
                            </ContentLoader>
                        ) : (
                            restaurants?.data.map((restaurant, index) => (
                                <RestaurantListItem key={index}
                                                    index={index}
                                                    lastChild={restaurants.length - 1 === index}
                                                    slides={promo_slides?.data}
                                                    selfpickup={!cart?.delivery}
                                                    restaurant={restaurant}
                                                    category_data={category_slides?.data}
                                                    selectedCategories={selectedCategories}
                                                    setSelectedCategories={setSelectedCategories}
                                />
                            ))
                        )}
                    </React.Fragment>
                )}
                <RestaurantListFooter/>
            </div>

        </React.Fragment>
    );
}
