import React from "react";
import {WEBSITE_URL} from "../../../../configs/website";

export function RestaurantListFooter() {
    return (
        <div className="restaurant-list-footer row">
            <div className="col-sm-3 footer-column">
                <ul className={'list-unstyled'}>
                    <li>
                        <span className={'footer-header'}>Contact Us</span>
                    </li>
                    <li>
                        <a href="tel:0223663786">📞 022 366 3786</a>
                    </li>
                    <li>
                        <a href="mailto:info@foodrunners.co.nz">📧 info@foodrunners.co.nz</a>
                    </li>
                    <li>
                        <a href='https://play.google.com/store/apps/details?id=nz.co.foodrunners.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className={'google-play-button'} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                    </li>

                </ul>
            </div>
            <div className="col-sm-3 footer-column">
                <ul className={'list-unstyled'}>
                    <li>
                        <span className={'footer-header'}>Follow us</span>
                    </li>
                    <li>
                        <a
                            href={"https://www.facebook.com/FoodRunnersWanganui/"}
                            className="footer-social-buttons "
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={WEBSITE_URL + '/assets/img/social/f_logo_RGB-Blue_144.png'}
                                 style={{height:'35px'}}
                                 alt={'Facebook Logo'}/>
                        </a>
                        <a
                            href={"https://www.instagram.com/foodrunnersnz/"}
                            className="footer-social-buttons "
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={WEBSITE_URL + '/assets/img/social/instagram.png'}
                                 style={{height:'35px'}}
                                 alt={'Instagram Logo'}/>
                        </a>
                    </li>

                </ul>
            </div>



            <div className="col-sm-3 footer-column">
                <ul className={'list-unstyled'}>
                    <li>
                        <span className={'footer-header'}>Join Us</span>
                    </li>
                    <li>
                        <a href="/pages/partner">Become a Restaurant Partner</a>
                    </li>
                    <li>
                        <a href="/pages/deliver">Become a Food Runner</a>
                    </li>


                </ul>
            </div>
            <div className="col-sm-3 footer-column">
                <ul className={'list-unstyled'}>
                    <li>
                        <span className={'footer-header'}>Information</span>
                    </li>
                    <li>
                        <a href="/pages/about-us">About Us</a>
                    </li>
                    <li>
                        <a href="/pages/terms">Terms and Conditions</a>
                    </li>
                    <li>
                        <a href="/pages/privacy">Privacy Policy</a>
                    </li>

                </ul>

            </div>
        </div>
    );
}
