import { LOGIN_STORE_OWNER_USER, LOGOUT_STORE_OWNER_USER } from "./actionTypes";

const initialState = {
    store_owner_user: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOGIN_STORE_OWNER_USER:
            return { ...state, store_owner_user: action.payload };
        case LOGOUT_STORE_OWNER_USER:
            console.log('loggingo ut?!')
            return { ...state, store_owner_user: action.payload };
        default:
            return state;
    }
}
