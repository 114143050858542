import React, {Component} from "react";

import ContentLoader from "react-content-loader";
import {NavLink} from "react-router-dom";
import Ink from "react-ink";
import {WEBSITE_URL} from "../../../configs/website";

export default function PromoSlider({slides, size, secondarySlider}) {
    return (
        <React.Fragment>
            <div
                className={
                    secondarySlider
                        ? "slider-wrapper secondary-slider-wrapper my-0"
                        : "slider-wrapper bg-light py-20 my-0"
                }
            >
                {slides.length === 0 ? (
                    <ContentLoader
                        height={170}
                        width={400}
                        viewBox={`0 0 400 170`}
                        speed={1.2}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="20" y="0" rx="4" ry="4" width="168" height="168"/>
                        <rect x="228" y="0" rx="4" ry="4" width="168" height="168"/>
                    </ContentLoader>
                ) : (
                    slides.map((slide) =>
                        slide.data.model === "3" ? (
                            <a
                                href={slide.url}
                                className="slider-wrapper__img-wrapper"
                                key={slide.data.id}
                                style={{position: "relative"}}
                            >
                                {/* if customURL then use anchor tag */}
                                <img
                                    src={WEBSITE_URL + slide.data.image}
                                    alt={slide.data.name}
                                    loading={"lazy"}
                                    className={`slider-wrapper__img slider-cust-img ${!secondarySlider &&
                                    "slider-wrapper__img-shadow"} custom-promo-img`}
                                />
                                <Ink duration="500" hasTouch={true}/>
                            </a>
                        ) : (
                            <NavLink
                                to={"../" + slide.url}
                                className="slider-wrapper__img-wrapper"
                                key={slide.data.id}
                                style={{position: "relative"}}
                            >
                                <img
                                    src={WEBSITE_URL + slide.data.image}
                                    alt={slide.data.name}
                                    loading={"lazy"}
                                    className={`slider-wrapper__img slider-cust-img ${!secondarySlider &&
                                    "slider-wrapper__img-shadow"} custom-promo-img`}
                                />
                                <Ink duration="500" hasTouch={true}/>
                            </NavLink>
                        )
                    )
                )}
            </div>
        </React.Fragment>
    );
}
