import React, {useEffect, useMemo} from "react";
import GoogleAnalytics from "react-ga4";

import {GET_DEFAULT_LANGUAGE_DATA_URL, GET_SETTINGS_URL} from "../../configs";
import {useQuery} from "@tanstack/react-query";
import Axios from "axios";
import Loading from "../helpers/loading";
import {useLocation} from "react-router-dom-v5-compat";

export default function App({children}) {
    const location = useLocation();
    useEffect(() => {
        if (localStorage.getItem("enableGoogleAnalytics") === "true") {
            GoogleAnalytics.initialize(localStorage.getItem("googleAnalyticsId"));
            GoogleAnalytics.gtag("event", "page_view", {
                page_location: location.href,
                page_path: location.pathname,
            });
        }

    }, [location]);
    const rand = useMemo(() => Math.floor(Math.random() * 100000), []);
    const {data:versions , isFetching:loadingVersions} = useQuery({
        queryKey: ['versions', rand],
        //the below hack allows dev to retrieve
        queryFn: () => Axios.get(`https://${window.location.hostname}/version.json?cb=${rand}`, {
            withCredentials: false,
        })
    })
    useEffect(() => {
        if (versions?.data?.forceNewSettingsVersion &&
            localStorage.getItem("forceNewSettingsVersion") !== versions?.data?.forceNewSettingsVersion)
            localStorage.setItem("forceNewSettingsVersion", versions?.data?.forceNewSettingsVersion);
        if (versions?.data?.forceCacheClearVersion &&
            localStorage.getItem("forceCacheClearVersion") !== versions?.data?.forceCacheClearVersion)
            localStorage.setItem("forceCacheClearVersion", versions?.data?.forceCacheClearVersion);
    }, [versions]);
    const [primaryLocationError, setPrimaryLocationError] = React.useState(false);
    const {data: settings, isFetching: settingsLoading} = useQuery(
        {
            queryKey: ["settings"],
            queryFn: () => Axios.get(GET_SETTINGS_URL + "?cb=" + versions?.data?.forceNewSettingsVersion),
            staleTime: Infinity,
            enabled: typeof versions?.data?.forceNewSettingsVersion !== "undefined" && versions?.data?.forceNewSettingsVersion !== localStorage.getItem("forceNewSettingsVersion"),
            refetchOnMount: false,
        }
    )
    useEffect(() => {
        if (settings?.data?.length) {
            console.log('saving settings')
            for (const setting of settings?.data) {
                localStorage.setItem(setting.key, setting.value);
            }
        }
    }, [settings]);

    const {data: language, isFetching: languageLoading} = useQuery(
        {
            queryKey: ["language"],
            queryFn: () => Axios.get(GET_DEFAULT_LANGUAGE_DATA_URL + "?cb=" + versions?.data?.forceCacheClearVersion),
            staleTime: Infinity,
            enabled: typeof versions?.data?.forceCacheClearVersion !== "undefined" && versions?.data?.forceCacheClearVersion !== localStorage.getItem("forceCacheClearVersion"),
            refetchOnMount: false,
        }
    )
    useEffect(() => {
        if (typeof language?.data === "object")
            for (const [key, value] of Object.entries(language?.data)) {
                localStorage.setItem(key, value);
            }
        try {
            if (typeof caches !== "undefined")
                caches.keys().then(function (names) {
                    for (let name of names) caches.delete(name);
                });
        } catch {}

    }, [language]);

    if (primaryLocationError)
        return (
            <div
                className="primaryLocationErrorCustomerApp pt-15 px-15"
                style={{minHeight: "100vh", backgroundColor: "#f0f0f0"}}
            >
                <div className="d-flex justify-content-center" style={{opacity: "0.5"}}>
                    <img src="https://www.freeiconspng.com/uploads/error-icon-3.png" alt="Error Message"/>
                </div>
                <h4 className="mb-0">Critical Error</h4>
                <p>Primary location is not set by Admin.</p>
                <h4 className="mb-2">Steps to resolve this issue</h4>
                <ol>
                    <li>Login to the Admin Dashboard</li>
                    <li>
                        You will automatically be redirected to the Popular Geo Location page{" "}
                        <span className="small">(or goto Settings > Popular Geo Locations)</span>
                    </li>
                    <li>
                        If you do not have any location, create a new location. If you already have locations,
                        mark anyone as primary{" "}
                        <span className="small">(by clicking the check-mark button)</span>
                    </li>
                    <li>Then reload this page</li>
                </ol>
            </div>

        )
    const anyLoading = settingsLoading || languageLoading || loadingVersions;
    if (anyLoading)
        return (
           <Loading/>
        )

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}

