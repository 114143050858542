import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {IconButton, Paper, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import {saveNotificationToken} from "../../../services/notification/actions";
import {SET_NOTIFICATIONS_ENABLED} from "../../../services/notification/actionTypes";
import {Close, NotificationAddOutlined} from "@mui/icons-material";
import React, {Dispatch, useState} from "react";
import {PwaPrompt} from "react-ios-pwa-prompt-ts";
import {firebaseConfig} from "../../../configs/consts";


export async function sendToken(dispatch: Dispatch<any>, user) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    const registration = registrations[0];
    if (!registration || typeof registration === "undefined") {
        console.log('no service worker registered, not initing FCM')
        return null;
    }
    const initializedFirebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(initializedFirebaseApp)
    getToken(messaging, {
        vapidKey: "BDNeiktYX5fYGlAjRsEG_8c8AQQZlmqCaQapnSA0elWb34x77pV4JFOy83vbkXsyWYnnQW9WAMnSty_KbFKW9KU",
        serviceWorkerRegistration: registration
    }).then((token) => {
        dispatch(saveNotificationToken(token, user.data.user_id, user.data.auth_token))
    })
    onMessage(messaging, (message) => {
        console.log(message)
    });
}

function IosInstructionPopup({show, setShow}) {
    if (typeof Notification === 'undefined' && !navigator.userAgent.includes('AppleWebKit')) return null;

    return (
        <PwaPrompt isOpen={show} onClose={() => setShow(false)}
                   copyBody={"Due to Apple requirements, you must add Food Runners to your home screen to receive live order updates."}
                   copyAddHomeButtonLabel={"2) Scroll down and tap \"Add to Home Screen\". You can now start using the Food Runners app from your home screen!"}
        />
    )
}

export default function RequestNotificationPermission({smallVersion}: { smallVersion?: boolean }) {
    const notificationsEnabled = useSelector((state) => state.notification_token.notifications_enabled);
    //the above means the user hasn't yet said NO to notifications
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [taps, setTaps] = useState(0);
    const [showIosPopup, setShowIosPopup] = useState(false);
    const notificationBlockedMessage = "Notifications have been disabled by your browser. Please check your browser settings to enable them.\n"
    if (typeof Notification === 'undefined' && !navigator.userAgent.includes('AppleWebKit')) return null;
    let permission
    try {
        permission = Notification?.permission;
        if (permission === "granted") return null;
    } catch (e) {
        permission = "default"
    } //fuck iphones
    if (navigator.userAgent === "FoodomaaAndroidWebViewUA") return null;
    const attemptPermission = async function () {
        setTaps(taps + 1);
        if (taps > 2) {
            setTimeout(() => setTaps(0), 5000);
        }
        if (typeof Notification === 'undefined' && navigator.userAgent.includes('AppleWebKit'))
            setShowIosPopup(true)
        let response;
        try {
            response = await Notification?.requestPermission();
        } catch (e) {
        } //fuck iphones
        if (response !== 'granted') {
            console.log('notification permission denied');
            return;
        }
        await sendToken(dispatch, user)
    }
    const dontWantNotifications = (e: Event) => {
        e.preventDefault();
        dispatch({type: SET_NOTIFICATIONS_ENABLED, payload: false});
    }
    if (!smallVersion && notificationsEnabled && permission !== "denied") {
        return (
            <React.Fragment>
                <IosInstructionPopup show={showIosPopup} setShow={setShowIosPopup}/>
                <Tooltip title={notificationBlockedMessage} open={taps > 2}>


                    <Paper sx={{padding: '10px', flexDirection: 'row', display: 'flex', gap: '4px', borderRadius: 0}}
                           elevation={3}>
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            <NotificationsActiveIcon/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            Enable notifications to be notified with live order status updates & special offers from
                            Food
                            Runners!
                        </div>
                        <div style={{marginLeft: 'auto'}}>
                            <Button sx={{fontWeight: 'bold'}} variant="contained" onClick={async (e) => {
                                e.preventDefault();
                                await attemptPermission()
                            }}>
                                Enable Notifications!
                            </Button>
                        </div>
                        <div style={{}}>
                            <IconButton onClick={dontWantNotifications}>
                                <Close/>
                            </IconButton>
                        </div>
                    </Paper>

                </Tooltip>
            </React.Fragment>
        )
    } else if (smallVersion) {
        return (
            <React.Fragment>
                <IosInstructionPopup show={showIosPopup} setShow={setShowIosPopup}/>
                <Tooltip title={notificationBlockedMessage} open={taps > 2}>
                    <IconButton
                        className={"btn search-navs-btns nav-home-btn"}
                        sx={{marginTop: '2px', padding: '0'}}
                        onClick={attemptPermission}
                    >
                        <NotificationAddOutlined fontSize={'inherit'}/>
                    </IconButton>

                </Tooltip>
            </React.Fragment>
        )
    } else {
        return null;
    }
}