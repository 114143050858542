import { NOTIFICATION_TOKEN } from "./actionTypes";
import {SET_NOTIFICATIONS_ENABLED} from "./actionTypes";

const initialState = {
    notification_token: [],
    notifications_enabled: true
};

export default function(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION_TOKEN:
            return { ...state, notification_token: action.payload };
        case SET_NOTIFICATIONS_ENABLED:
            return { ...state, notifications_enabled: action.payload };
        default:
            return state;
    }
}
