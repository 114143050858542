import {Provider} from "react-redux";
import React from "react";
import store from "./services/store";
import {InAppNotification} from "./components/Mobile/InAppNotification";
import CustomCssProvider from "./components/CustomCssProvider";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import './main.css'
import './custom.css'
import Axios from "axios";

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];


const polling = {
    enabled: false,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                if (failureCount > MAX_RETRIES) {
                    return false;
                }

                if (
                    Axios.isAxiosError(error) &&
                    HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
                ) {
                    console.log(`Aborting retry due to ${error.response?.status} status`);
                    return false;
                }

                return true;
            },
        },

    }
});

const Root = ({children, initialState = {}}) => (
    <React.Fragment>
        <Provider store={store(initialState)}>
            <QueryClientProvider client={queryClient}>
                {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                <CustomCssProvider/>
                <div
                    className="height-100 overlay-loading hidden"
                    style={{backgroundColor: "rgba(96, 125, 139, 0.45)"}}
                    id="gpsLoadingScreen"
                >
                    <div className="spin-load"/>
                </div>

                    {children}
                    <img className="cart-empty-img hidden" src="/assets/img/various/offline.png" alt="offline"/>
                    {/*<CheckVersion/>*/}
                    <InAppNotification/>

            </QueryClientProvider>
        </Provider>
    </React.Fragment>
);



export default Root;
