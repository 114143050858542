export const ordertypeIdToName = (id) => {
    const statusTextMap = {
        [ORDER_STATUS_NEW]: "orderPlacedStatusText",
        [ORDER_STATUS_ACCEPTED]: "preparingOrderStatusText",
        [ORDER_STATUS_DELIVERY_ASSIGNED]: "deliveryGuyAssignedStatusText",
        [ORDER_STATUS_DELIVERY_PICKED_UP]: "orderPickedUpStatusText",
        [ORDER_STATUS_DELIVERED]: "deliveredStatusText",
        [ORDER_STATUS_CANCELLED]: "canceledStatusText",
        [ORDER_STATUS_READY_FOR_PICKUP]: "readyForPickupStatusText",
        [ORDER_STATUS_AWAITING_PAYMENT]: "awaitingPaymentStatusText",
        [ORDER_STATUS_PAYMENT_FAILED]: "paymentFailedStatusText",
        [ORDER_STATUS_SCHEDULED_ORDER]: "scheduledOrderStatusText",
        [ORDER_STATUS_CONFIRMED]: "confirmedOrderStatusText"
    };

    return localStorage.getItem(statusTextMap[parseInt(id)] || "");
}
export const ORDER_STATUS_NEW = 1;
export const ORDER_STATUS_ACCEPTED = 2;
export const ORDER_STATUS_DELIVERY_ASSIGNED = 3;
export const ORDER_STATUS_DELIVERY_PICKED_UP = 4;
export const ORDER_STATUS_DELIVERED = 5;
export const ORDER_STATUS_CANCELLED = 6;
export const ORDER_STATUS_READY_FOR_PICKUP = 7;
export const ORDER_STATUS_AWAITING_PAYMENT = 8;
export const ORDER_STATUS_PAYMENT_FAILED = 9;
export const ORDER_STATUS_SCHEDULED_ORDER = 10;
export const ORDER_STATUS_CONFIRMED = 11;
export const DELIVERY_TYPE_DELIVERY = 1;
export const DELIVERY_TYPE_PICKUP = 2;

export const RESTAURANT_ALLOWS_DELIVERY_ONLY = 1;
export const RESTAURANT_ALLOWS_PICKUP_ONLY = 2;
export const RESTAURANT_ALLOWS_BOTH = 3;

export const APP_URL = "https://play.google.com/store/apps/details?id=nz.co.foodrunners.app";

export const firebaseConfig = {
    apiKey: "AIzaSyAM8BB7LB-NGJRVJOLRCkhI04c9M3lDxh4",
    authDomain: "food-runners-v2.firebaseapp.com",
    databaseURL: "https://food-runners-v2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "food-runners-v2",
    storageBucket: "food-runners-v2.appspot.com",
    messagingSenderId: "249338253054",
    appId: "1:249338253054:web:e3061060342e8f92d5dfaf",
    measurementId: "G-64K9PSSJL8"
};