import React, {useEffect} from "react";
import {register} from '../../serviceWorker'
import {isSupported} from "firebase/messaging";
import {useDispatch, useSelector} from "react-redux";
import {sendToken} from "./RunningOrder/requestNotificationPermission";


export function InAppNotification() {
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    useEffect(() => {
        const start = async () => {
            if (await isSupported()) {
                console.log("hello this is inappnotification, I see supported!");
                register()
                if (Notification.permission === "granted")
                    await sendToken(dispatch, user);
            }

        }
        start();
    }, []);
    return <div></div>
}