import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

export default (initialState) => {
	initialState = JSON.parse(window.localStorage.getItem("state")) || initialState;
	const middleware = [thunk];

	const store = createStore(
		rootReducer,
		initialState,
		compose(
			applyMiddleware(...middleware),
			// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);

	store.subscribe(() => {
		const state = store.getState();


		const persist = {
			pages: state.pages,
			user: state.user,
			delivery_user: state.delivery_user,
			settings: state.settings,
			store_owner_user: state.store_owner_user,
			notification_token: state.notification_token,
			// searches: state.searches,
		};
		localStorage.setItem("state", JSON.stringify(persist));

		// const user = {
		//     user: state.user
		// };
		// window.localStorage.setItem("user", JSON.stringify(user.user));
	});

	return store;
};
