import React from "react";

import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {WEBSITE_URL} from "../../../configs/website";
import {useGetCartQuery} from "../../../services/cart/queries";

export default function Footer({
                                   active_nearme,
                                   active_account,
                                   active_alerts,
                                   active_cart,
                                   active_orders,

                               }) {
    const {isLoading:loadingCart, data: cart} = useGetCartQuery()
    const alertUnreadTotal = useSelector(state => state.alert.alertUnreadTotal);

    return (
        <React.Fragment>

            <div className="d-flex">
                <div
                    className={`content pt-10 py-5 font-size-xs clearfix ${
                        localStorage.getItem("footerStyleType") === "FLOAT" ? "footer-float" : "footer-fixed"
                    }`}
                >
                    <NavLink to="/" className="col footer-links px-2 py-1">
                        <i className="si si-pointer fa-2x"/> <br/>
                        <span className={active_nearme ? "active-footer-tab" : ""}>
								{active_nearme ? (
                                    localStorage.getItem("footerNearme")
                                ) : (
                                    <span> {localStorage.getItem("footerNearme")}</span>
                                )}
							</span>
                    </NavLink>
                    <NavLink to="/alerts" className="col footer-links px-2 py-1">
							<span
                                className="cart-quantity-badge"
                                style={{backgroundColor: localStorage.getItem("storeColor")}}
                            >
								{alertUnreadTotal}
							</span>
                        <i className="si si-bell fa-2x"/> <br/>
                        <span className={active_alerts ? "active-footer-tab" : ""}>
								{active_alerts ? (
                                    localStorage.getItem("footerAlerts")
                                ) : (
                                    <span> {localStorage.getItem("footerAlerts")}</span>
                                )}
							</span>
                    </NavLink>
                    <NavLink to="/cart" className="col footer-links px-2 py-1">
                        <i className="si si-bag fa-2x"/> <br/>
                        <span className={active_cart ? "active-footer-tab" : ""}>
								{active_cart
                                    ? localStorage.getItem("footerCart")
                                    : localStorage.getItem("footerCart")}
                            <span
                                className="cart-quantity-badge"
                                style={{backgroundColor: localStorage.getItem("storeColor")}}
                            >
									{loadingCart ? (
                                        <i className="fa fa-spinner fa-spin"/>
                                    ) : (
                                        cart?.cartItems?.total_quantity
                                    )}
								</span>
							</span>
                    </NavLink>
                    <NavLink to={"/my-orders"} className="col footer-links px-2 py-1">
                        <img style={{height: '28px', objectFit: 'contain', marginTop: '-4px'}} src={WEBSITE_URL + '/assets/img/shopping-bag.png'} alt={'Orders'}/> <br/>
                        <span className={active_orders ? "active-footer-tab" : ""}>
                                {active_orders ? (
                                    "Orders"
                                ) : (
                                    <span>Orders</span>
                                )}
                            </span>
                    </NavLink>
                    <NavLink to="/my-account" className="col footer-links px-2 py-1">
                        <i className="si si-user fa-2x"/> <br/>
                        <span className={active_account ? "active-footer-tab" : ""}>
								{active_account
                                    ? localStorage.getItem("footerAccount")
                                    : localStorage.getItem("footerAccount")}
							</span>
                    </NavLink>
                </div>
            </div>
        </React.Fragment>
    );

}