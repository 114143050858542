import {useGetCartQuery, useSetDeliveryTypeMutation} from "../../../../services/cart/queries";
import React from "react";
import {
    RESTAURANT_ALLOWS_BOTH,
    RESTAURANT_ALLOWS_DELIVERY_ONLY,
    RESTAURANT_ALLOWS_PICKUP_ONLY
} from "../../../../configs/consts";

export default function DeliveryTypeSwitch({ big = false, showDeliveryType = RESTAURANT_ALLOWS_BOTH }:
{ big?: boolean, showDeliveryType?: number}) {

    const setDeliveryTypeMutation = useSetDeliveryTypeMutation()
    const {isLoading: loadingCart, data: cart} = useGetCartQuery()
    if (loadingCart) return null

    const showDelivery = showDeliveryType === RESTAURANT_ALLOWS_BOTH || showDeliveryType === RESTAURANT_ALLOWS_DELIVERY_ONLY
    const showSelfPickup = showDeliveryType === RESTAURANT_ALLOWS_BOTH || showDeliveryType === RESTAURANT_ALLOWS_PICKUP_ONLY

    return (
        <div className="d-flex btn-group btn-preference-group">
            {showDelivery && <button
                onClick={() => {
                    setDeliveryTypeMutation.mutate(true)
                    // window.scrollTo({ top: 190, behavior: "smooth" });
                }}
                className={
                    "btn btn-preference " +
                    (!!cart?.delivery ? "user-preferred " : "") +
                    (big ? "btn-preference-large " : "")
                }
            >
                {localStorage.getItem("deliveryTypeDelivery")}
            </button>}
            {showSelfPickup && <button
                onClick={() => {
                    setDeliveryTypeMutation.mutate(false)
                    // window.scrollTo({ top: 190, behavior: "smooth" });
                }}
                className={
                    "btn btn-preference " +
                    (!cart?.delivery
                        ? "user-preferred "
                        : "") +
                    (big ? "btn-preference-large " : "")
                }
            >
                {localStorage.getItem("deliveryTypeSelfPickup")}
            </button>}
        </div>
    )
}