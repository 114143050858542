import {Restaurant} from "../../../configs/types";

export function isDeviceAndroid() {
    if (navigator.userAgent.match(/Android/i) && navigator.userAgent !== 'FoodomaaAndroidWebViewUA') {
        return true;
    }
}

export function tryParseJSONObject(jsonString: string): boolean {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {
    }

    return false;
};



export function restaurantAcceptsScheduleOrders(restaurant: ?Restaurant): boolean {
    const obj = tryParseJSONObject(restaurant?.schedule_data);
    if (obj === false || Object.keys(obj).length === 0) {
        return false;
    }
    return restaurant?.is_schedulable === 1 &&
        restaurant?.accept_scheduled_orders === 1 &&
        localStorage.getItem("enableOrderSchedulingOnCustomer") === "true";
}