import {CircularProgress} from "@mui/material";
import React from "react";

export default function Loader() {

    return (<div style={{
        display: 'flex',
        width: '100%',
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <CircularProgress/>
    </div>)
}